<template>
  <div class="flex-column flex-grow">
    <div class="flex-grow flex-center mb">
      <div>
        <div class="center">
          <img class="icon mb" src="@/assets/images/books.svg"><br>
          <div class="title1 mb-8">{{ $t('quickNote.thanks') }}</div>
          <div class="text3 mb">{{ $t('quickNote.help') }}</div>
        </div>
        <div class="text2">
          <p>{{ $t('quick-note.to_apply_for_the') }} <strong>10
            minutes</strong>. Be aware, all the fields are required.</p>
          <p>
            {{ $t('quick-note.please_prepare_your') }}
            <strong>{{ $t('quick-note.cv') }}</strong>,
            <strong>{{ $t('quick-note.prc_license') }}</strong> and <strong>current employment
            contract</strong> (if you have one).</p>
          <p>{{ $t('quick-note.check_all_the_data_you_ve') }}</p>
          <p>{{ $t('quick-note.good_luck') }} <img class="smile" src="@/assets/images/good_luck.png"></p>
        </div>
      </div>
    </div>
    <ui-button @click="next">{{ $t('quickNote.buttonText') }}</ui-button>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton';

export default {
  components: { UiButton },
  layout: 'auth',
  name: 'quick-note',
  methods: {
    next() {
      global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'start_application_form');
      this.$router.push({ path: '/filling/basic-info' });
    }
  }
}
</script>

<style scoped>
.smile {
  vertical-align: middle;
  margin-left: 5px;
}

p {
  margin-bottom: 8px;
}
</style>
